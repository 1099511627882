/* General styles */

body {
	font-family: "DM Sans", sans-serif;
	font-optical-sizing: auto;
	background-color: #f0f2f5;
	margin: 0;
	padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Inria Serif", serif;
}


/* Header styles */
header {
	background-color: #3b5998;
	color: white;
	padding: 10px 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

header a {
	color: white;
	text-decoration: underline;
	margin-right: 20px;
}

header img {
	height: 30px;
}

.parent-container{
	background-image: url(https://tailwindui.com/img/beams-basic.png);
	background-color: #fff;
	background-size: 100%;
	background-repeat: no-repeat;
	min-height: 100vh;
	background-attachment: fixed;
}



/* Form styles */
form {
	background-color: white;
	max-width: 1100px;
	margin: 20px auto;
	padding: 20px;
	border-radius: 10px;
	border: 1px solid #ddd ;

}

form input {
	width: calc(100% - 20px);
	padding: 10px;
	border: 1px solid #ddd;
	border-radius: 5px;
}

/* Links to switch between pages */
.link-container {
	text-align: center;
	margin-top: 10px;
}

.link-container a {
	color: #3b5998;
}

.logout-button {
	padding: 10px;
	font-weight: bold;
	background-color: #ab0000;
	color: white;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	transition-duration: 500ms;
}

.logout-button:hover {
	background-color: #fff;
	color: #3b5998;
}

.add-product {
	margin-bottom: 20px;
}

/* Styles for the dropdown */
select {
	width: 100%;
	padding: 10px;
	margin-bottom: 10px;
	border: 1px solid #ddd;
	border-radius: 5px;
}

/* Styles for the delete button */
button.delete {
	background-color: #ff4d4d;
	color: white;
	border: none;
	padding: 5px 10px;
	margin-top: 5px;
	border-radius: 5px;
	cursor: pointer;
	margin-bottom: 20px;
}

button.delete:hover {
	background-color: #ff0000;
}

.form-group {
	margin-bottom: 10px;
}

.form-group label {
	display: block;
	margin-bottom: 5px;
}

.form-group input[type="text"],
.form-group input[type="number"],
.form-group select {
	padding: 8px;
	border: 1px solid #ccc;
	border-radius: 4px;
}

.form-group .radio-group {
	display: flex;
	align-items: center;
}

.form-group .radio-group label {
	display: inline-flex;
	align-items: center;
	margin-right: 10px;
}

.form-group .radio-group input[type="radio"] {
	margin-right: 5px;
	margin-bottom: 0px;
}

.product-form {
	margin-bottom: 10px;
	margin-top: 10px;
}

.border-right-one{
	border: solid;
	border-right: 1px;
	color:grey;
}

.form-group .radio-group input[type="text"] {
	margin-bottom: 0px;
}


product-form {
    padding: 1.5rem;
    background-color: #ffffff;
    border-radius: 0.75rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .form-group {
    margin-bottom: 1.5rem;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: #374151;
    font-size: 0.875rem;
    font-weight: 600;
    letter-spacing: 0.025em;
  }
  
  .form-group select,
  .form-group input[type="number"],
  .form-group input[type="text"] {
    width: 100%;
    padding: 0.625rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
    background-color: #f9fafb;
    color: #1f2937;
    font-size: 0.875rem;
    transition: all 0.2s ease-in-out;
  }
  
  .form-group select:hover,
  .form-group input[type="number"]:hover,
  .form-group input[type="text"]:hover {
    border-color: #d1d5db;
  }
  
  .form-group select:focus,
  .form-group input[type="number"]:focus,
  .form-group input[type="text"]:focus {
    outline: none;
    border-color: #3b82f6;
    ring: 2px;
    ring-color: rgba(59, 130, 246, 0.5);
    background-color: #ffffff;
  }
  
  .form-group input[type="checkbox"],
  .form-group input[type="radio"] {
    width: 1rem;
    height: 1rem;
    border: 1px solid #d1d5db;
    border-radius: 0.25rem;
    margin-right: 0.5rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  
  .form-group input[type="radio"] {
    border-radius: 50%;
  }
  
  .form-group input[type="checkbox"]:checked,
  .form-group input[type="radio"]:checked {
    background-color: #3b82f6;
    border-color: #3b82f6;
  }
  
  .grid {
    display: grid;
    gap: 1rem;
  }
  
  .grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  
  .flex {
    display: flex;
  }
  
  .items-center {
    align-items: center;
  }
  
  .space-x-4 > * + * {
    margin-left: 1rem;
  }
  
  /* Special styling for the image */
  .product-form img {
    width: 100%;
    max-width: 400px;
    height: auto;
    margin-bottom: 1.5rem;
    border-radius: 0.5rem;
  }
  
  /* Hover effects for interactive elements */
  .form-group label:hover {
    cursor: pointer;
  }
  
  .form-group select option {
    padding: 0.5rem;
  }
  
  /* Responsive adjustments */
  @media (max-width: 640px) {
    .grid-cols-2 {
      grid-template-columns: 1fr;
    }
    
    .space-x-4 > * + * {
      margin-left: 0.5rem;
    }
  }